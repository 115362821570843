.toolbar-drawer {
  padding: 2px;
  width: 50%;
  z-index: 99 !important;
  position: sticky;
  top: 90px;
  left: 100%;
  right: 0;
  border-bottom-left-radius: 50px;
  float: right;
}

.toolbar-close-btn {
  rotate: 270deg;
  width: 15.5rem;
  margin-left: -7.5rem;
  margin-right: -6rem;
  margin-top: 4.1rem;
  border-top-left-radius: 20px;
}

.toolbar-close-btn:hover {
  background-color: #c4c8ce;
  cursor: pointer;
}

.tool-drawer {
  height: 230px;
}

.tool-drawer .ant-drawer-content-wrapper {
  border-bottom-left-radius: 50px !important;
}

.tool-drawer .ant-drawer-content {
  border-bottom-left-radius: 50px !important;
}
