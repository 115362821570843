.ant-drawer {
  z-index: 2 !important;
  margin-top: 84px !important;
}

.ant-drawer-body {
  padding: 10px !important;
}

.filter-drawer {
  height: 450px;
  width: 450px;
}
