@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./styles/index.css");

@font-face {
  font-family: "Diodrum-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  src: local("Diodrum-Regular"), url("fonts/Diodrum-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Diodrum-Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  src: local("Diodrum-Semibold"), url("fonts/Diodrum-Semibold.otf") format("opentype");
}
* {
  font-family: "Diodrum-Regular";
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Diodrum-Semibold";
}

body {
  background-image: url("./assets/body-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
