table,
th,
td {
  font-size: 14px;
  border: 1px solid rgb(225, 225, 225);
  border-collapse: collapse;
  padding: 5px;
}

thead {
  background-color: rgb(234, 234, 234);
  width: 500px !important;
  position: sticky !important;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  font-size: 0.8rem;
  height: 75px;
  max-width: 150px !important;
  min-width: 150px !important;
}
td {
  padding: 1px;
  max-width: 150px;
  min-width: 150px;
  align-items: center;
  vertical-align: center;
  max-height: 40px;
  height: 50px;
  align-items: center;
}

/* .filter-list {
    margin-top: 40px;
} */

.row-select {
  min-width: 5px;
  max-width: 5px;
}

/* #first-table-container::-webkit-scrollbar {
    width: 0;
    height: 0;
} */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #696969;
}

.first-table-container::-webkit-scrollbar {
  width: 0px;
}

.table-filter-icon.selected {
  border-top-color: red !important;
  box-shadow: inset 0 4px red !important;
}

.filter-list-item {
  z-index: 9 !important;
}

.filter-list {
  /* position: relative !important; */
  top: 20px !important;
  /* left: 70px !important; */
  z-index: 90 !important;
}

.sort-parent {
  z-index: 10 !important;
}
.required-field:first-child {
  border-color: #00c8ff;
}
.required-field .ant-select-selector {
  border-color: #00c8ff;
}

.error-field {
  border-color: red !important;
}

.final-table-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 0 10px;
  background-color: white;
  overflow: auto;
  min-height: 50vh;
  max-height: 80vh;
}

.first-table-container {
  min-width: 50px;
}
